/**
 * @fileoverview gRPC-Web generated client stub for gotemplate
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.1
// source: server.proto

/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as server_pb from './server_pb';


export class GreetingClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGreet = new grpcWeb.MethodDescriptor(
    '/gotemplate.Greeting/Greet',
    grpcWeb.MethodType.UNARY,
    server_pb.GreetingRequest,
    server_pb.GreetingResponse,
    (request: server_pb.GreetingRequest) => {
      return request.serializeBinary();
    },
    server_pb.GreetingResponse.deserializeBinary
  );

  greet(
    request: server_pb.GreetingRequest,
    metadata: grpcWeb.Metadata | null): Promise<server_pb.GreetingResponse>;

  greet(
    request: server_pb.GreetingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: server_pb.GreetingResponse) => void): grpcWeb.ClientReadableStream<server_pb.GreetingResponse>;

  greet(
    request: server_pb.GreetingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: server_pb.GreetingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/gotemplate.Greeting/Greet',
        request,
        metadata || {},
        this.methodDescriptorGreet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/gotemplate.Greeting/Greet',
    request,
    metadata || {},
    this.methodDescriptorGreet);
  }

}

