import {useState, useCallback} from 'react';
import logo from './logo.svg';
import {Button} from '@verily-src/react-design-system';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {GreetingClient} from './types/ServerServiceClientPb';
import {GreetingRequest} from './types/server_pb';

type ItemListProperties = {
  items: string[];
  remove: (index: number) => void;
};

function ItemList(properties: ItemListProperties) {
  const {items, remove} = properties;
  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          key={index}
          secondaryAction={
            <IconButton edge="end" role="delete" onClick={() => remove(index)}>
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemText>{item}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

function AddItemForm() {
  const [item, setItem] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    let host = '/api';
    if (process.env.NODE_ENV === 'development') {
      host = 'http://localhost:8080';
    }
    const client = new GreetingClient(host, null, null);
    const request = new GreetingRequest().setName(item);
    client.greet(request, {}, (err, response) => {
      if (err) {
        console.log(err);
      }
      console.log(`response: ${response}`);
      console.log(`response.getGreetings(): ${response.getGreetings()}`);
      setResponse(response.getGreetings());
    });
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setItem(event.target.value);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack direction="row" spacing={1}>
        <TextField
          size="small"
          label="New item"
          id="item"
          onChange={handleChange}
          value={item}
        />
        <Button type="submit" role="add" disabled={item === ''}>
          Add
        </Button>
      </Stack>
      <TextField value={response} />
    </Box>
  );
}

function TaskListApplication() {
  const [items, setItems] = useState<string[]>([]);

  const removeItem = useCallback(
    (index: number) => {
      setItems([...items.slice(0, index), ...items.slice(index + 1)]);
    },
    [items, setItems]
  );

  return (
    <>
      <Container maxWidth="xs">
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" spacing={1}>
              <img src={logo} />
              <Typography>Greeting service!</Typography>
            </Stack>
          </Toolbar>
        </AppBar>
        <ItemList items={items} remove={removeItem} />
        <AddItemForm />
      </Container>
    </>
  );
}

export default TaskListApplication;
